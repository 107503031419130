import React from 'react';

import Button from '@atlaskit/button/standard-button';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalTransition,
  } from '@atlaskit/modal-dialog';

import AmazonSmile from './../AmazonSmile.png';
import EF from './../ef-logo.png';
import Paypal from './../paypal-button.png';
import PaypalQR from './../PaypalQR.png';
import Stiffkey from './../Stiffkey.png';

export default class HomePage extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			showPaypalQR: false
		};
	}

	openPaypalQR() {
		this.setState({
			showPaypalQR: true
		});
	}

	closePaypalQRCode() {
		this.setState({
			showPaypalQR: false
		});
	}

	render() {
		return (
		
		<div style={{maxWidth: "90%"}}>
			<ModalTransition>
				{this.state.showPaypalQR && (
					<Modal onClose={this.closePaypalQRCode.bind(this)}>
						<ModalBody>
							<div style={{textAlign:"center", paddingTop: "20px"}}>
								<img src={PaypalQR} alt=""/>
							</div>
							
						</ModalBody>
						<ModalFooter>
							<div style={{textAlign:"center"}}>
								<Button appearance='primary' onClick={this.closePaypalQRCode.bind(this)}>Close</Button>
							</div>
						</ModalFooter>
					</Modal>
				)}
			</ModalTransition>
			<div>
				<img style={{maxWidth:"300px"}} src={Stiffkey} alt=""/>
				<h3>Our Heritage</h3>
				<p>In 1936, the village of Stiffkey was gifted the land on which the village hall currently stands
for use as a community space. Over the years, the community worked together to build
and develop this space for everyone to enjoy.</p>
<p>
The last time this was done was in 1981, reflecting community needs at that time.</p>
			</div>
			<div>
				<h3>Our Future</h3>
				<p>
					The time has come to revise and refurbish the existing space to make it more compatible with the needs of our community in the 21st century.</p>
				<p>
					Harnessing the same community spirit that built the hall, the Village Hall Committee is now looking to preserve and improve this asset for current and future generations.
				</p>
				<p>
					To make this more achievable, the work will be undertaken in phases so that the village hall can still be used while work is undertaken.
				</p>
				<p>
					To do this, we need your help and support!
				</p>
			</div>
			<div>
				<h3>Our Plan</h3>
				<p>
					The proposal is to demolish some of the southern section to create a sightly smaller
					building with a new main entrance with a disabled access ramp. This will create additional
					parking spaces to better meet needs. This new community hub will enable us to offer
					fantastic facilities which our community will be able to use now and well into the future.
				</p>
			</div>
			<div>
				<h3>How You Can Help</h3>
				<table>
					<tbody>
						<tr>
							<td>
								<a href="https://www.smile.amazon.co.uk">
									<img style={{maxWidth:"300px"}} src={AmazonSmile} alt=""/>
								</a>
							</td>
						</tr>
						<tr>
							<td>
								<p>
							By shopping with Amazon Smile, you can contribute directly to Stiffkey Village Hall easily and simply – and at
							no cost to you.
							Just go to <a href="https://www.smile.amazon.co.uk">www.smileamazon.co.uk</a> and select Stiffkey Village Hall as your chosen organisation.
							 To do this in the
							mobile app, go to Settings &gt; AmazonSmile, and change the charity to Stiffkey Village Hall.
							Stiffkey Village Hall will receive 0.5% of the value of any order you place with Amazon.</p>
							</td>
						</tr>
						<tr>
							<td>
								<a href="https://www.easyfundraising.org.uk/causes/stiffkeyvillagehall/">
									<img style={{maxWidth:"300px"}} src={EF} alt=""/>
								</a>
							</td>
						</tr>
						<tr>
							<td>
								<p>
								By shopping via the easyfundraising website, you can support Stiffkey
								Village Hall while you shop with thousands of retailers including Argos,
								John Lewis, ASOS, Expedia, eBay, Boden, and M&S.
								To do so, just click <a href="https://www.easyfundraising.org.uk/causes/stiffkeyvillagehall/">here</a> and create an account – it’s easy, quick and simple.
								Every time you shop, you’ll raise a free donation for Stiffkey Village Hall
								every time - it really is that easy!</p>
							</td>
						</tr>
						<tr>
							<td>
								<a href="https://www.paypal.com/donate/?hosted_button_id=PF8SV35HT4FM8">
									<img style={{maxWidth:"500px"}} src={Paypal} alt=""/>
								</a>
							</td>
						</tr>
						<tr>
							<td>
								<p>
							Donating via the PayPal Giving Fund is safe
							and secure. PayPal will deliver 100% of your
							donation to charity. To donate, simply scan the <Button onClick={this.openPaypalQR.bind(this)} spacing='none' appearance='link'>QR Code</Button>, or go to: <br></br>
							<a href="https://www.paypal.com/donate/?hosted_button_id=PF8SV35HT4FM8">https://www.paypal.com/donate/?hosted_button_id=PF8SV35HT4FM8</a></p>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>);
	}
}